import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Slider = props => {
  const {
		desktopSettings,
		tabletSettings,
		mobileSettings,
		aspectRatio,
		imagesPadding,
		borderRadius,
		arrowsColor,
		dotsColor,
		imageSize,
    slides,
  } = props;

	const styles = {
		slide: {
			width: '100%',
			height: '100%',
			paddingTop: `${aspectRatio * 100}%`,
			borderRadius,
			backgroundSize: imageSize,
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat'
		}
	}

	const sliderSettings = {
		...desktopSettings,
		responsive: [
			// Mobile
			{
				breakpoint: 640,
				settings: {...mobileSettings}
			},
			// Tablet
			{
				breakpoint: 1000,
				settings: {...tabletSettings}
			},
		]
	};


  return (
    <div className='slider' style={{width: 'calc(100% - 100px)', margin: 'auto'}}>
			<SlickSlider {...sliderSettings}>
				{
					slides?.map((slide, i) =>
					<div
						key={i}
					>
						<div style={{
							width: '100%',
							padding: imagesPadding,
						}}>
							<div
								style={{
									...styles.slide,
									backgroundImage: `url(${slide.image}`
								}}
							/>
						</div>
					</div>
					)
				}
			</SlickSlider>
    </div>
  );
};
