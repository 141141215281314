import { Builder } from '@builder.io/react';
import { Slider } from './Slider';

Builder.registerComponent(Slider, {
  name: 'Images Slider',
  // Optionally give a custom icon (image url - ideally a black on transparent bg svg or png)
  image:
    'https://cdn.builder.io/api/v1/image/assets%2FYJIGb4i01jvw0SRdL5Bt%2Fd6d3bc814ffd47b182ec8345cc5438c0',
  inputs: [
    {
      name: 'slides',
      type: 'list',
      broadcast: true,
      subFields: [
				{
					name: 'image',
					type: 'file',
					allowedFileTypes: ['jpeg', 'jpg', 'png', 'svg'],
					defaultValue: null
				},
      ],
    },
    {
      name: 'imageSize',
      helperText: 'Should be contained or cover the container?',
      type: 'string',
      enum: ['contain', 'cover'],
      defaultValue: 'cover',
    },
    {
      name: 'aspectRatio',
      helperText: 'Aspect ratio of each slide',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'imagesPadding',
      helperText: 'Padding of each element in the slider (in Pixels)',
      type: 'number',
      defaultValue: 0,
    },
    {
      name: 'borderRadius',
      type: 'number',
      defaultValue: 0,
    },
    {
      name: 'arrowsColor',
      type: 'color',
      defaultValue: '#000000',
    },
    {
      name: 'dotsColor',
      type: 'color',
      defaultValue: '#000000',
    },
    {
      name: 'desktopSettings',
      type: 'object',
      broadcast: true,
      defaultValue: {
        slidesToShow: 3,
      },
      subFields: [
        {
          name: 'slidesToShow',
          helperText: 'How many slides to show in one frame',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'slidesToScroll',
          helperText: 'How many slides to scroll at once',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'infinite',
          helperText: 'Infinitely wrap around contents',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'arrows',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'dots',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'speed',
          helperText: 'Delay between each auto scroll (in milliseconds)',
          type: 'number',
          defaultValue: 500,
        },
      ],
    },
    {
      name: 'tabletSettings',
      type: 'object',
      broadcast: true,
      defaultValue: {
        slidesToShow: 3,
      },
      subFields: [
        {
          name: 'slidesToShow',
          helperText: 'How many slides to show in one frame',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'slidesToScroll',
          helperText: 'How many slides to scroll at once',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'infinite',
          helperText: 'Infinitely wrap around contents',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'arrows',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'dots',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'speed',
          helperText: 'Delay between each auto scroll (in milliseconds)',
          type: 'number',
          defaultValue: 500,
        },
      ],
    },
    {
      name: 'mobileSettings',
      type: 'object',
      broadcast: true,
      defaultValue: {
        slidesToShow: 3,
      },
      subFields: [
        {
          name: 'slidesToShow',
          helperText: 'How many slides to show in one frame',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'slidesToScroll',
          helperText: 'How many slides to scroll at once',
          type: 'number',
          defaultValue: 3,
        },
        {
          name: 'infinite',
          helperText: 'Infinitely wrap around contents',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'arrows',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'dots',
          type: 'boolean',
          defaultValue: true,
        },
        {
          name: 'speed',
          helperText: 'Delay between each auto scroll (in milliseconds)',
          type: 'number',
          defaultValue: 500,
        },
      ],
    },
  ]
});
